export default function EmailAutocomplete(selector, options) {
  var els = document.querySelectorAll(selector);
  var options = options || {};
  var domains = options.donaims
                ? (Array.isArray(options.donaims) ? options.donaims : [options.donaims])
                : ['gmail.com', 'yahoo.com.tw', 'yahoo.com', 'hotmail.com', 'live.com', 'msn.com'];
  var defaultCLassName = 'email-autocomplete-options';
  var className = options.className || '';
  var prefix = options.prefix
               ? options.prefix + '-'
               : 'email-autocomplete-options-';
  var optionsBoxTagName = 'DIV'; // 判斷 node.tagName 需用「大寫」比對

  setOptionsBoxStyle();

  els.forEach(function(el, index) {
    init(el, index);
  })

  return els;

  function init(el, index) {
    // 若已經做過 init，已插入後接的 dom 時，刪除原有的 dom
    var optionsBox = getOptionsBox(el);
    if (optionsBox !== null)
      optionsBox.remove();

    setElement(el, index);
    bindEvents(el, index);
  }

  function getOptionsBox(el) {
    if (
      el.nextElementSibling !== null
      && el.nextElementSibling.tagName == optionsBoxTagName
      && el.nextElementSibling.classList[0] == defaultCLassName
    ) {
      console.warn('EmailAutocomplete 重複實例化，已強制更新選項用之 dom');
      return el.nextElementSibling;
    }

    return null;
  }

  function setElement(el, index) {
    var optionsBox = document.createElement(optionsBoxTagName);
    optionsBox.setAttribute('id', prefix + index);
    optionsBox.setAttribute('class', defaultCLassName + ' ' + className);

    el.parentNode.insertBefore(optionsBox, el.nextSibling); // 'optionsBox' insert after 'el'
  }

  function bindEvents(el, index) {
    var optionsBox = document.querySelector('#' + prefix + index);

    el.addEventListener('input', function(event) { // 用 input 而非 keyup，防止左右方向鍵觸發
      var value = event.target.value;
      var indexOfAt = value.indexOf('@');
      var doanimStr = value.substr(indexOfAt + 1);

      // if (indexOfAt >= 1) { // 有輸入 '@' 且前面有一個以上字元
      if (indexOfAt >= 1 && doanimStr) { // 有輸入 '@' 且前面有一個以上字元，後續有一個以上字元
        var name = value.split('@')[0];
        setOptionsBoxPosition(el, optionsBox);
        addOptionItems(el, optionsBox, name, doanimStr);
      } else {
        removeOptions(optionsBox);
      }

      // 完全輸入時，刪除選項
      optionsBox.childNodes.forEach(function(item) {
        if (item.textContent == value) {
          removeOptions(optionsBox);
        }
      });
    });

    el.addEventListener('keydown', function(event) {
      var pressEnterOrTab = event.key == 'Enter' || event.code == 'Enter' || event.key == 'Tab' || event.code == 'Tab';
      if (pressEnterOrTab && optionsBox.hasChildNodes()) {
        el.value = optionsBox.firstChild.textContent;
        removeOptions(optionsBox);
      }
    });

    el.addEventListener('focusout', function() {
      if (optionsBox.hasChildNodes()) {
        el.value = optionsBox.firstChild.textContent;
        removeOptions(optionsBox);
      }
    });

    window.addEventListener('resize', function() {
      setOptionsBoxPosition(el, optionsBox);
    });
  }

  function addOptionItems(el, optionsBox, name, doanimStr) {
    var newOptionsFragment = document.createDocumentFragment();
    var includeDonaim = false;

    for (var i = 0; i < domains.length; i++) {
      var newOptionValue = name + '@' + domains[i];

      if ( ! includeDonaim && domains[i].indexOf(doanimStr) === 0) {
        includeDonaim = true;
      } else {
        continue;
      }

      if ( ! optionsBox.querySelector('[data-value="' + newOptionValue + '"]')) {
        var newOption = document.createElement('div');
        newOption.dataset['value'] = newOptionValue;
        newOption.textContent = newOptionValue;
        newOptionsFragment.appendChild(newOption);
      }
    }

    if (includeDonaim) {
      optionsBox.appendChild(newOptionsFragment);
      optionsBox.style.display = 'block';
      optionsBox.childNodes.forEach(function(item) {
        item.addEventListener('click', function(e) {
          el.value = e.target.textContent;
          removeOptions(optionsBox);
        });
      });
    } else {
      removeOptions(optionsBox);
    }
  }

  function setOptionsBoxStyle() {
    var selector = '.' + defaultCLassName;
    var optionsBoxStyle = selector + " {\n" +
                          'display: none;' +
                          'position: absolute;' +
                          'margin: 0 !important;' +
                          'background-color: rgb(253, 253, 253);' +
                          'overflow: scroll;' +
                          'text-align: left;' +
                          'box-sizing: border-box;' +
                          'z-index: 10;' +
                          'border: 1px solid rgb(233, 233, 233);' +
                          'box-shadow: 1px 1px 6px -2px rgba(20, 20, 20, 0.2);' +
                          "\n}";

    var optionsStyle = selector + " > div {\n" +
                       'font-size: .94rem;' +
                       'padding: .46em .68em;' +
                       'color: rgb(116, 116, 116);' +
                       'cursor: pointer;' +
                       'transition: all 260ms;' +
                       "\n}";

    var optionsHoverStyle = selector + ' > div:hover { color: rgb(124, 124, 124); background-color: rgb(243, 243, 243); }';

    var optionsBoxTag = document.createElement('style');
    document.head.append(optionsBoxTag);
    optionsBoxTag.sheet.insertRule(optionsBoxStyle, 0);
    optionsBoxTag.sheet.insertRule(optionsStyle, 1);
    optionsBoxTag.sheet.insertRule(optionsHoverStyle, 2);
  }

  function setOptionsBoxPosition(el, optionsBox) {
    optionsBox.style.top = el.offsetTop + el.offsetHeight + 'px';
    optionsBox.style.left = 'calc('
                            + el.offsetLeft + 'px'
                            + ')';
    optionsBox.style.width = 'calc('
                             + el.offsetWidth + 'px'
                             + ' - ' + window.getComputedStyle(el).borderRightWidth + ' / 2'
                             + ')';
  }

  function removeOptions(optionsBox) {
    optionsBox.innerHTML = ''; // remove childs
    optionsBox.style.display = 'none'; // hide
  }

}
// /EmailAutocomplete
